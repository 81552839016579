// init handlers
let layoutHandler = null;
let translatorHandler = null;
let formHandler = null;

const initHandlers = () => {
  layoutHandler = new LayoutHandler();
  translatorHandler = new TranslatorHandler();
  formHandler = new FormHandler();

};

// uncomment this to use jQuery
(($) => {
  $(document).ready(() => {
    initHandlers();
  });
})(jQuery);

// init handlers using vanilla
// document.addEventListener('DOMContentLoaded', () => { initHandlers(); });
