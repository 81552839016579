class TranslatorHandler extends OutcomesHandler {
  constructor() {
    super();

    if ($(".translator-page").length > 0) {
      this.init();
      this.handleDOM();
      this.handleEvents();
    }
  }

  /**
   * Declare global variables
   */
  init() {
    this.currentStep = 1;
    this.swiperInstance = null;
    this.selectValues = {
      ownGeneration: {
        initValue: null,
        currentValue: null,
      },
      toKnowGeneration: {
        initValue: null,
        currentValue: null,
      },
      theme: {
        initValue: null,
        currentValue: null,
      },
    };
    this.results = [];
    this.avatarPaths = {
      boomer: `${absolutePath}/translator/boomer-avi.png`,
      genX: `${absolutePath}/translator/genx-avi.png`,
      millennial: `${absolutePath}/translator/millennial-avi.png`,
      genZ: `${absolutePath}/translator/genz-avi.png`,
      expressions: `${absolutePath}/translator/expresii-avi.png`,
      entertainment: `${absolutePath}/translator/entertainment-avi.png`,
      technology: `${absolutePath}/translator/tehnologie-avi.png`,
      lifestyle: `${absolutePath}/translator/lifestyle-avi.png`,
      personal: `${absolutePath}/translator/stil-avi.png`
    };
  }

  /**
   * Handle DOM queries
   */
  handleDOM() {
    this.translatorSteps = document.querySelectorAll(".translator-wrapper [data-step]");
    this.finalStepContainer = document.querySelector(".translator-wrapper [data-step-final]");
    this.swiperContainer = document.querySelector(".translator-step-final-wrapper .swiper-container");
    this.swiperWrapper = document.querySelector(".translator-step-final-wrapper .swiper-wrapper");

    this.nextStepBtns = document.querySelectorAll(".translator-step .button-next");
    this.generateResultsBtn = document.querySelector(".translator-step .generate-results");
    this.resetBtn = document.querySelector(".translator-wrapper .button-reset");

    this.selectFields = document.querySelectorAll(".translator-wrapper .tandrete-select");
  }

  /**
   * Listen for events
   */
  handleEvents() {
    this.initSelectValues();

    this.selectFields.forEach((select) => {
      select.addEventListener("change", (e) => {
        const { value, name } = e.target;

        this.selectValues[name].currentValue = value;
        this.updateStepAvatar(value, this.currentStep);
      });
    });

    this.nextStepBtns.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();

        this.currentStep++;
        const nextStepNode = document.querySelector(`[data-step='${this.currentStep}']`);

        if (nextStepNode) {
          this.translatorSteps.forEach((step) => {
            step.classList.add("d-none");
          });

          nextStepNode.classList.remove("d-none");
        }
      });
    });

    this.generateResultsBtn.addEventListener("click", (e) => {
      e.preventDefault();

      const {
        ownGeneration,
        toKnowGeneration,
        theme: selectedTheme,
      } = this.selectValues;
      const currentStepContainer = e.currentTarget.closest("[data-step]");

      currentStepContainer.classList.add("d-none");
      this.finalStepContainer.classList.remove("d-none");

      this.results = this.translatorOutcomes.filter(({ stepTwo, theme }) => stepTwo === toKnowGeneration.currentValue && theme === selectedTheme.currentValue);

      this.drawResults();
    });

    this.finalStepContainer.addEventListener("click", (e) => {
      e.preventDefault();

      const targetClassList = e.target.classList;

      // STIAM / NU STIAM buttons - TO DO AJAX requests
      if (targetClassList.contains("unaware")) {
        console.log("NU STIAM button");
      } else if (targetClassList.contains("aware")) {
        console.log("STIAM button");
      }
    });

    this.resetBtn.addEventListener("click", (e) => {
      e.preventDefault();

      this.currentStep = 1;
      this.resetTranslator();

      const firstStepContainer = document.querySelector(`[data-step='${this.currentStep}']`);

      firstStepContainer.classList.remove("d-none");
      this.finalStepContainer.classList.add("d-none");
    });
  }

  initSelectValues() {
    this.selectFields.forEach((select) => {
      const { name, value } = select;

      const selectStateKeys = Object.keys(this.selectValues[name]);

      selectStateKeys.forEach((state) => {
        this.selectValues[name][state] = value;
      });

      const { step } = select.closest('.translator-step').dataset;

      this.updateStepAvatar(value, step);
    });
  }

  resetTranslator() {
    if (this.swiperInstance) {
      this.swiperInstance.destroy();
      this.swiperInstance = null;
    }

    this.selectFields.forEach((select) => {
      const { name } = select;

      const { initValue } = this.selectValues[name];

      this.selectValues[name].currentValue = initValue;
      select.value = initValue;

      const { step } = select.closest('.translator-step').dataset;

      this.updateStepAvatar(initValue, step);
    });

    this.swiperWrapper.innerHTML = "";
    this.results = [];
  }

  updateStepAvatar(selectValue, step) {
    const currentStepAvatars = document.querySelectorAll(`.step${step}-avi`);

    currentStepAvatars.forEach((avatarImg) => {
      avatarImg.src = this.avatarPaths[selectValue];
    });
  }

  drawResults() {
    let template = "";

    this.results.forEach(
      ({ category, title, description, shareImage, shareUrl }) => {
        template += `
        <div class="swiper-slide">
          <h5>
            <span class="theme">${category}</span>
            <span class="category">${title}</span>
          </h5>
          <p>${description}</p>
          <div class="buttons">
            <a href="#" class="unaware">
              <img
                src="https://placehold.co/40x40/png"
                alt="NU ȘTIAM"
                width="16"
                height="16"
              />
              NU ȘTIAM
            </a>
            <a href="#" class="aware">
              <img
                src="https://placehold.co/40x40/png"
                alt="ȘTIAM"
                width="16"
                height="16"
              />
              ȘTIAM
            </a>
          </div>
          <div class="btn-wrapper">
            <a href="#" class="button button-primary swiper-next">Următoarea traducere</a>
          </div>
          <div class="swiper-pagination"></div>
          <div class="btn-wrapper-share">
            <a href="https://www.facebook.com/sharer/sharer.php?u=${shareUrl}" class="share" target="_blank">
              <img
                src="https://placehold.co/40x40/png"
                alt="SHARE PE FACEBOOK"
                width="15"
                height="15"
              />
              SHARE PE FACEBOOK
            </a>
          </div>
        </div>
      `;
      }
    );

    this.swiperWrapper.innerHTML = template;

    // const swiperNavigation = document.createElement("div");
    // swiperNavigation.classList.add("swiper-pagination");
    // this.swiperContainer.append(swiperNavigation);

    this.initSwiper();
  }

  initSwiper() {
    this.swiperInstance = new Swiper(".translator-step-final-wrapper .swiper-container", {
      slidesPerView: 1,
      pagination: {
        el: ".translator-step-final-wrapper .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".translator-step-final-wrapper .swiper-next",
      },
    });
  }
}
