class OutcomesHandler {
  constructor() {
    this.translatorOutcomes = [
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "E ruptă din stele",
        description: "Expresie folosită pentru a descrie ceva atât de frumos și strălucitor încât pare că face parte din corpurile cerești. Un compliment cu adevărat înălțător, ca gustul Milka.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A face capul calendar",
        description: "Se folosește atunci când persoane care dețin foarte multe informații vor să le împărtășească, în ciuda faptului că nu sunt cerute sau nu sunt folositoare. Dar poate unele sunt.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Acarul Păun",
        description: "Un om real în vremurile de demult. Acum imaginea omului cinstit, muncitor, dar care, printr-o serie de întâmplări mai puțin fericite, ajunge să fie considerat țap ispășitor, vinovat pentru un rezultat negativ.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Vorbe de clacă",
        description: "Discuțiile de relaxare folosite, în trecut, de muncitorii câmpului pentru a face timpul să treacă mai repede. Astăzi este o expresie folosită atunci când cineva vorbeşte inutil sau neserios.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Unde se cioplește, sar și așchii",
        description: "Un mod tandru de a spune că acolo unde este foc, iese fum. Sau că unele lucruri se întâmplă cu un motiv. O povață demnă de a fi reținută.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A fi la gură de cuptor",
        description: "Dacă cineva îți spune asta, înseamnă că are mare grijă de tine pentru că te avertizează de o mare primejdie.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Aceeași Mărie cu altă pălărie",
        description: "Un mod frumos de a spune “e același lucru”, dar cu mai multă poezie.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Terchea-berchea",
        description: "Rimele sunt o metodă tandră de a face cuiva un compliment mai puțin dorit, cum ar fi că e un om cam neserios, pe care nu prea te poți baza.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A sta în capul oaselor",
        description: "Dacă nu știai că oasele au un cap, nu te îngrijora, nu au. Această expresie înseamnă “a te ridica stând în pat, a sta în șezut”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A boscorodi",
        description: "Atunci când vrei să spui ceva mai puțin tandru, oarecum fără să se audă, înseamnă că boscorodești pe cineva.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A aduce noră pe cuptor",
        description: "A aduce o noră în casă sau un mod sigur de a o face pe mama ta fericită, chiar dacă are un cuptor sau nu.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Dârz",
        description: "Acum spunem curajos, cutezător, hotărât sau îndrăzneț. În alte vremuri, dârzenia era una dintre calitățile de preț ale unui om.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A drege busuiocul",
        description: "Expresie folosită atunci când ai făcut o gafă și vrei să salvezi totuși aparențele. A se folosi doar cu busuioc, nu cu ciocolată.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A umbla cu cioara vopsită",
        description: "Fii cu ochii în patru, această expresie însemnă că cineva este necinstit, incorect, minte, încearcă să prezinte o situație ca fiind cu totul altfel decât este de fapt.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Nici în clin, nici în mânecă",
        description: "În Transilvania, “în mânecă” înseamnă la deal, iar “în clin“ la vale, așadar, când cineva stă pe loc, el nu e nici în clin, nici în mânecă. Sau, pe scurt, “a nu avea nimic comun, niciun amestec, nicio legătură”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Când o face plopul mere și răchita micșunele",
        description: "Dacă vrei să spui frumos că ceva n-o să se întâmple niciodată, foloseștea această expresie poetică.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A catadicsi",
        description: "Atunci când oamenii doreasu să spună că cineva a binevoit să facă ceva, spuneau că a catadicsit. Adică în final au găsit de cuviință să facă acel lucru.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Madmoazelă / Demoazelă",
        description: "Un termen tandru, de la francezi, pentru a denumi o fată nemăritată. Sau o duduie.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Este atmosferă / antren",
        description: "Un mod de a spune că petrecerea este pe gustul tău, că îți creează o stare bună... ca o ciocolată Milka.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A dispărut ca măgarul în ceață",
        description: "Când cineva dispare brusc, pe neașteptate... la fel cum se întâmplă uneori și cu ciocolata preferată.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "I-a picat fisa",
        description: "Când o persoană își dă seama (de obicei cu o oarecare întârziere) de ceva.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Mai cu talent",
        description: "O formă mai tandră decât altele de a zori pe cineva, de a grăbi puțin desfășurarea lucrurilor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "E beton",
        description: "Adică minunat. Expresie folosită pentru a exprima faptul că un lucru este la superlativ.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Te aburește",
        description: "Înseamnă a amăgi, a păcăli pe cineva. Ai grijă atunci când auzi această expresie și verifică toată informația de două ori.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "E valabil",
        description: "Este o expresie folosită pentru a denumi ceva ce îți place, accepți, corespunde suficient standardelor tale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A freca menta",
        description: "A pierde timpul, a nu face nimic. (Recomandarea noastră: dacă freci menta, fă-o în stil mare, în timp ce te bucuri de Milka preferată. Măcar să obții și ceva bun din asta.)",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Este fashion",
        description: "Expresie folosită pentru a descrie ceva ușor ieșit din comun, care are stil, este la modă, în tendințe.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Perfect match",
        description: "Atunci când ceva ți se potrivește, un lucru cu care faci pereche perfect. Tu și Milka.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Ghosting",
        description: "Când cineva întrerupe complet legătura cu altcineva într-un mod abrupt, fără explicații și fără pic de tandrețe. Nu-ți face griji, noi nu facem asta.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Cool",
        description: "Termen folosit pentru a descrie o persoană cu un comportament remarcabil sau ceva care are calități deosebite, cu o atitudine pe măsură.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Dude",
        description: "Cum se adresează milenialii unii altora. Se poate traduce ca „omule”, “frate”, și se folosește în contexte de tip „Dude, sper că n-ai mâncat toată Milka!”",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "CSF",
        description: "Pe scurt, „Aia e, ce să-i faci?”. Spui cu o notă ușoară de regret despre lucruri pe care nu le poți schimba. Urmat adesea de „NCSF” („N-ai ce să faci.”)",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Gen",
        description: "Derivat românesc al englezescului „like”, poate fi înțeles drept „de exemplu” sau „cum ar fi”. Folosește-l pentru o notă tandră de familiaritate.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Buzz",
        description: "Amintire tandră din trecutul adolescenței milenialilor: pe o platformă de chat celebră, când voiai să întrebi „Hei, e cineva acolo?”, foloseai o sonerie digitală cu care „dădeai buzz”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Mass",
        description: "Adică mesaje trimise către toată lumea din lista de prieteni: „Cine poate să treacă pe la mine să-mi aducă o Milka? Scuze de mass!”",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "BRB",
        description: "Prescurtarea împrumutată din engleză, care se traduce prin „mă întorc imediat” și poate fi urmată de „revin cu Milka”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A fi blană",
        description: "Ceva foarte bun, excelent, peste așteptări. Variantă și mai tandră: „blanao”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Întoarce caseta",
        description: "Pe alte vremuri, casetele cu muzică aveau două fețe (A și B) și trebuiau întoarse cu tandrețe ca să nu se strice.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "ASAP",
        description: "O expresie pentru a marca urgența, o prescurtare din engleză care înseamnă “cât de curând posibil”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Efectiv",
        description: "În traducere liberă, „literalmente”. De fapt, un cuvânt ușor inutil, care subliniază o idee, importanța unui sentiment.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Vis",
        description: "O expresie care se folosește atunci când ceva place în mod excepțional. Un termen tandru pentru “nemaipomenit”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Te vrăjește / umblă cu vrăjeală",
        description: "Expresie folosită atunci când cineva lingușește și te amăgește ca să obțină ceva bun de la tine.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Dă-mi un beep",
        description: "A fost o vreme când apelurile telefonice erau „pe impulsuri”, iar impulsurile costau o mică avere. De aceea, se obișnuia să se dea un „beep” prietenilor (sunai scurt și închideai) și se aștepta să sune ei înapoi.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Frăție / Frățică / Nenicule",
        description: "Una dintre formulele de adresare din generația trecută, folosită de obicei între bărbați, conține o încărcătură mai redusă de tandrețe decât ar putea sugera diminutivarea.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "E un papagal",
        description: "Un mod deloc tandru de a eticheta un individ ușor de manipulat, despre care crezi că nu e mare lucru de capul lui.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Chef",
        description: "Termen așteptat de toți cei din această generație în tinerețe și însemna „Partyyyyy!”. E o diferență mare între a spune „Am chef de Milka” și „Fac chef cu Milka”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "A rupe fâșul",
        description: "Expresie folosită pentru a exprima ceva ce „rupe gura târgului”, este nemaipomenit, ieșit din tipare.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "BFF",
        description: "Împrumutat din engleză, termenul înseamnă cel mai bun prieten / cea mai bună prietenă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Glow up",
        description: "Termen folosit pentru a denumi o schimbare în bine pe plan fizic sau chiar psihic, o transformare de stil, ținută sau atitudine.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Ador",
        description: "Un mod de a spune pe scurt, tandru și la obiect că cineva iubește ceva, este fix pe sufletul lui. Cum este gustul Milka pentru toată lumea.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Woke",
        description: "O mișcare apărută recent sau un termen folosit pentru a descrie o persoană care știe ce este ofensator pentru anumite minorități, grupuri minoritare și ce cuvinte sau fapte sunt inadmisibile sau inexprimabile în spațiul public.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Flex",
        description: "Un mod de a spune tandru că cineva s-a încordat să își arate “mușchi” metaforici, că se laudă cu ceva.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Snack / snacc",
        description: "Un termen tandru pentru a descrie ceva atât de drăguț încât îți vine să îl/o mănânci ca pe o gustărică.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Smol",
        description: "Un fel de diminutive tandru pentru ceva care este deja foarte mic și, cel mai probabil, și foarte adorabil.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Bestie",
        description: "Vine de la termenul “best friends” care înseamnă “cei mai buni prieteni”/”cele mai bune prietene”, o prescurtare tandră și un alint pentru prieten vechi și bun.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "FOMO",
        description: "Acronimul expresiei “fear of missing out ”, adică frica de a pierde ceva important sau de a rata ceva, într-o lume în care opțiunile sunt atââât de multe.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Kind of",
        description: "Un mod tandru de a spune “ești pe-aproape”, “pe-acolo” cuiva.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Boujee / Bougie",
        description: "Termen venit din zona muzicală hip-hop care definea un om care trăiește un stil de viață luxos, dar are un caracter umil în același timp.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "POV",
        description: "Acronimul “point of view”, expresie folosită pe rețelele de socializare în videouri care reprezintă experiența personală a cuiva, fără a folosi videouri personale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "AMR",
        description: "Acronimul “a mai rămas”, inițial folosit pentru a denumi zilele rămase dintr-un stagiu militar, acum folosit pentru a denumi zilele rămase până la orice eveniment fericit.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Gurl",
        description: "Este echivalentul tandru feminin al lui “bro”, “dude” sau neaoșul “frate”. Se folosește cu o intonație pozitivă și în sens admirativ.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Epic fail",
        description: "Expresie folosită pentru a descrie ceva atât de greșit încât e epic, o eroare de proporții atât de mari, încât devine subiect de poveste.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "ish",
        description: "O terminație care se poate alătura unui adverb sau adjectiv pentru a-l diminua. Folosit singur, termenul exprimă o senzație moderată cu privire la un subiect anume. Adica “nici așa, nici așa”.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Baddie",
        description: "Termen din engleză care desemna personajul negativ dintr-o carte sau un film. Astăzi e un mod tandru de a descrie de cele mai multe ori o femeie care are multe calități, e independentă și curajoasă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Ți-am lăsat PM",
        description: "O expresie folosită atunci când cineva ți-a lăsat un mesaj privat. Versus a lăsa un mesaj public pe rețelele sociale. Uneori, în privat e mai bine.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Gaslight",
        description: "Este un termen ce descrie fenomenul nociv și deloc tandru în care un om este convins că ceea ce crede nu este adevărat sau că memoria sa nu mai funcționează.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "expressions",
        category: "Expresii de zi cu zi",
        title: "Bop",
        description: "Un alt mod de a spune că o piesă muzicală este hit sau este în topurile preferințelor pentru că traducerea sa este “lovitură”, deci un cântec a dat lovitura.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Disc",
        description: "Străbunicul tandru, de vinil, al sunetului digital, care este din nou la mare modă printre audiofili.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Muzică ușoară",
        description: "Termen tandru din istoria antică a popului, odinioară antonimul muzicii clasice, rock sau populare.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Recital",
        description: "Concertul susținut de un singur solist sau la un singur instrument, cum ar fi un recital tandru de vioară.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Romanțe",
        description: "Melodii tandre de dragoste și melancolie în care, de obicei, interpretul cântă o poveste personală.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Șlagăr",
        description: "Melodie, de obicei pop, care este foarte la modă într-o anumită perioadă. Sinonimul mai în vârstă pentru hit.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Cântece de petrecere",
        description: "Melodii vesele care se cântau la evenimente sau sindrofii și contribuiau la întreținerea atmosferei.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Filme alb-negru sau mute",
        description: "La începuturile cinematografiei, mergeam la film să ne bucurăm cu tandrețe de emoția unor povești fără cuvinte, culori și chiar fără sunet. Abia mai târziu replicile au început să apară, iar lumea să se coloreze.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "entertainment",
        category: "Entertainment",
        title: "Pantomimă",
        description: "Teatru mut, susținut de obicei pe stradă de un actor care prezintă acțiunea doar cu ajutorul mișcărilor și al mimicii. Cum ai mima gustul Milka?",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Reality show",
        description: "Spectacol cu oameni reali, puși în fața unor situații surprinzătoare sau în contexte neobișnuite. Entertainmentul vine din reacțiile mai mult sau mai puțin tandre ale oamenilor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Discotecă / rockotecă",
        description: "Rockoteca este discoteca specializată în muzică rock. Ambele aveau rolul pe care îl are clubul acum în materie de distracție.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Numismatică",
        description: "Termenul venit de la francezi descrie studiul tipurilor de monede, categoriilor existente, descrierea lor și a legendelor. O activitate tandră de petrecut timpul liber pentru cei atenți la detalii.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Închiriere de filme",
        description: "Înainte să existe serviciile de streaming și filmele să fie disponibile oricând, oamenii obișnuiau să meargă la centrele specializate și să închirieze casete fizice pentru o anumită perioadă de timp.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Casetă",
        description: "Video sau audio, caseta era suportul pe care se afla conținutul preferat al oamenilor și era ușor de transportat, colecționat și, de cele mai multe ori, redau cele mai tari playlisturi ale momentului.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Telenovelă",
        description: "Denumirea data genului de seriale de televiziune, orginar din America Latină, în anii '50. Poveștile melodramatice făceau deliciul publicului, erau subiectele cele mai întâlnite de discuții, iar finalul tandru era mai mereu fericit.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Headbanger",
        description: "Termen ce descrie mișcarea de cap pe ritmul muzicii, de obicei cu ritmuri vioaie, intense. Des întâlnit la stilurile rock sau rap.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Disco",
        description: "Stilul muzicii anilor '70 a venit la pachet și cu un stil de îmbrăcăminte și cu idei de libertate și liberă exprimare care încă sunt apreciate.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Poster",
        description: "Aflat de obicei la centrul unei reviste de specialitate, posterul era, de cele mai multe ori, milocul prin care fanii unui cântăreț sau ai unei trupe puteau să îi aibă aproape pe idolii lor, acesta devenind un obiect decorativ nelipsit din camerele tinerilor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Revelion TV",
        description: "O modalitatea de a petrece revelionul în familie, programele de televiziune din noaptea dintre ani erau pline de momente diverse, muzicale sau de comedie care făceau deliciul publicului care le urmărea din fața mesei pline cu bunătăți.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Reviste cu benzi desenate",
        description: "Apărute în Japonia, revistele cu benzi desenate erau o modalitatea distractivă de a urmări întâmplările eroilor preferați.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "entertainment",
        category: "Entertainment",
        title: "Folk",
        description: "Un amestec dulce de poezie și muzică, a fost la un moment dat cel mai tandru mod de a transmite un mesaj social.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "Follower",
        description: "Termen ce descrie utilizatorii rețelelor sociale care urmăresc un anumit cont. Comunitățile de followeri sunt pentru creatorii de conținut de pe rețelele sociale ca un grup tandru de prieteni.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "Rave",
        description: "Termen colocvial folosit de britanici pentru a descrie o petrecere la care oamenii se distrează fără inhibiții, la maximum.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "Hip-hop",
        description: "Stil muzical care presupune combinația rapului cu un fond mzuical, de obicei electronic.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "Techno",
        description: "Stil muzical cu ritm rapid, electronic care conține puține versuri, sau chiar deloc.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "Hit",
        description: "Termen ce descrie o piesă muzicală foarte apreciată și ascultată care se află pe primul loc în topuri și care evocă amintiri tandre pentru foarte mulți oameni.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "VJ",
        description: "Dacă DJ-ul este cel care pune muzică, VJ-ul este cel care se ocupă de mixul de vizualuri ce acompaniază muzica live, asigurându-se că acestea se potrivesc și completează ceea ce se aude și oferă o stare plăcută.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "entertainment",
        category: "Entertainment",
        title: "Playlist",
        description: "Termenul descrie o serie de piese muzicale aranjate într-o anumită ordine, după stil, sau chiar după preferințe. Adaugă boxele potrivite și ai petrecerea asigurată.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "Vlog",
        description: "În esență, un jurnal video - o formă modernă de documentare a unor experiențe personale, foarte apreciată de urmăritori.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "Photo dump",
        description: "Colecție de fotografii, uneori și video, cât mai puțin editate, puse pe rețelele sociale. Împreună, ele formează o poveste sau contribuie la crearea unei stări tandre sau vesele.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "Anime",
        description: "Animații japoneze cu povești complexe și stiluri de desen cu o aromă aparte, simplificate. Spre exemplu, personajele au deseori ochii de dimensiuni exagerate sau își mișcă buzele foarte puțin atunci când vorbesc.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "K-pop",
        description: "Muzică pop tandră, de origine coreeană, cu dansuri specifice, foarte la modă peste tot în lume. Ce au Milka și curentul k-pop în comun? Culoarea mov.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "DNB (drum & bass)",
        description: "Fiindcă și tandrețea trebuie pusă uneori în balanță cu ceva, există DNB - o muzică de dans electronică modernă, cu ingrediente precum ritmuri rapide, repetitive, și frecvențe puternice de bas.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "Trap",
        description: "Un stil de muzică derivat din rap, cu mult bas și frecvențe înalte. Se asortează foarte bine cu petrecerile sau cu sesiunile de fitness.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "Minimal",
        description: "Stil muzical care demonstrează cu succes faptul că uneori mai puțin înseamnă mai mult. Minimalul se bazează pe limitarea instrumentelor sau sunetelor folosite, precum și a compoziției melodice prin repetiția acelorași fraze scurte.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "Dubstep",
        description: "Un stil muzical cu o atmosferă aparte, bazat pe ingrediente precum ruperi de ritm și oscilații de bas.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "entertainment",
        category: "Entertainment",
        title: "DJ",
        description: "Responsabilul cu muzica și atmosfera la petreceri sau la radio. E cel care sincronizează tandru melodiile cu tonul general și cu emoțiile celor care o ascultă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Magnetofon",
        description: "Dispozitiv de redare și înregistrare de sunete și muzică pe bandă, tatăl casetofonului.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Gramofon",
        description: "Străbunicul și mai tandru al pick-upului. În loc de difuzoare avea o pâlnie mare de metal prin care reda sunetele de pe discuri de vinil.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Pick-up",
        description: "Aparat vechi, cu aer boem, pentru ascultat discuri de vinil, readaptat timpurilor moderne. Foarte iubit de audiofili.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Telefon fix",
        description: "Tip de telefon antic, de apartament, cu disc și fir, dar fără memorie și ecran. Funcționa împreună cu o carte de telefon unde găseai numerele tuturor persoanelor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Mașină de scris",
        description: "Înainte de evoluția calculatorului, textele se scriau cu mașina de scris - o tastatură mecanică gigant, cu un singur font.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Carte poștală",
        description: "Numite și „vederi”, erau cartonașe cu imagini din peisajul local pe spatele cărora puteai scrie mesaje tandre către un destinatar.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "technology",
        category: "Tehnologie",
        title: "Lampă cu gaz",
        description: "Un soi de felinar portabil, cu flacără, alimentat cu gaz, care asigura o atmosferă tandră în orice loc lipsit de lumină.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Aparat foto cu film",
        description: "Înainte de smartphone pozele se făceau în doi pași tandri. Aparatul foto imprima imaginea pe un film, urmând ca acesta să fie developat într-o cameră specială, obscură.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Pe fix",
        description: "Pe când un smartphone era doar visul îndrăzneț al scriitorilor SF, ne sunam „pe fix”, adică pe telefonul cu fir de acasă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Fax",
        description: "Combinație antică de e-mail, imprimantă și număr de telefon, folosită pentru transmis documente.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Walkman",
        description: "Mici casetofoane portabile la care îți puteai asculta muzica tandră preferată.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Diafilm",
        description: "Serie de diapozitive care, proiectate în ordine, alcătuiau din imagini statice o poveste tandră.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Diapozitive",
        description: "Imagini statice imprimate pe un film de celuloid, care puteau fi proiectate la dimensiuni mari cu ajutorul unui aparat special numit proiector. Numai bun pentru seri tandre de amintiri alături de prieteni.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Casetofon",
        description: "Aparatul care făcea să răsune tandru muzica de pe casete. - oare nu ar fi mai bine sa il mutam la Generatia X? Cred ca ei au fost de fapt cei care au crescut cu ele.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Înregistrare pe casetă",
        description: "Când au apărut casetofoanele cu înregistrare și reportofoanele pentru interviuri, puteai înregistra sunete sau copia conținutul altor casete pe casete goale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "Pager",
        description: "Un fel de smartphone mic și străvechi pe care puteai primi mesaje scurte. Acestea te informau că trebuie să suni la un anumit număr ca să afli despre ce e vorba.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "technology",
        category: "Tehnologie",
        title: "VHS",
        description: "Aparat cu care puteai reda conținut video de pe videocasete cu o simplă apăsare tandră de buton.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Celular",
        description: "Numele mai tandru pentru primele generații de smartphone.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "App / aplicație",
        description: "Programe - de obicei pentru telefoanele mobile - concepute pentru a rezolva funcții specifice.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Laptop",
        description: "Computere portabile și elegante care funcționează cu baterii proprii.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Notebook",
        description: "Laptop de dimensiuni reduse, aproape „de buzunar”... nu cu mult mai mare decât o ciocolată Milka.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Bluetooth",
        description: "Tehnologie wireless concepută pentru transmiterea de date sau încărcarea ușoară și tandră a unor dispozitive moderne.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "mp3",
        description: "Formatul cel mai răspândit pentru fișierele audio. Probabil că și tu le folosești cel mai des atunci când îți asculți melodiile preferate, pline de tandrețe.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "mp4",
        description: "Unul dintre cele mai cunoscute formate pentru fișiere video. Perfect pentru serile de... Milka & chill.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Smart home",
        description: "Când lucrurile din casă sunt conectate la Internet și uneori mai inteligente decât te-ai aștepta, le controlezi tandru printr-o aplicație și le protejezi cu ajutorul unui program de securitate cibernetică.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Combină muzicală",
        description: "La fel cum Milka are combinații de ingrediente pentru cât mai multe gusturi, combina muzicală era un combo de radio, casetofon și CD player pentru împătimiții de muzică.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Plasmă",
        description: "Numele tandru dat primelor televizoare mai subțiri care puteau fi puse pe perete.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Dischetă",
        description: "Primele dispozitive de stocare de date externe la care ne gândim și acum cu tandrețe. Ca idee, un singur hard disk modern poate găzdui acum proiectele, fotografiile sau melodiile care încăpeau, cumulat, sute de mii dischete.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "DVD",
        description: "Urmașul direct al CD-ului, acest dispozitiv de stocare identic ca aspect putea înmagazina de aproape 7 ori mai multă informație sau muzică tandră.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Tamagotchi",
        description: "Primele relații tandre dintre oameni și animăluțe virtuale s-au stabilit pe aceste dispozitive portabile cu ecran simplu.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "technology",
        category: "Tehnologie",
        title: "Emoji",
        description: "Simboluri simpatice folosite încă de la prima generație care a înlocuit convorbirile la telefon cu mesajele scrise. Adăugând textului emoji-uri, e mult mai ușor să iți exprimi tandrețea sau orice altă stare.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Smartphone",
        description: "Telefon mobil modern, conectat la Internet, care are ecran color și tactil, camere foto, difuzoare și numeroase aplicații cu care ne face ziua mai bună și mai tandră.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Consolă",
        description: "Dispozitive dezvoltate în mod specific pentru jocuri video, folosite în tandem cu un televizor. Una dintre distracțiile preferate pentru întreceri cu prietenii sau cu familia.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "A.I.",
        description: "Prescurtarea termenului „Inteligență artificială”, adică inteligența unei mașini care se străduiește să învețe de la noi pentru a ne fi de ajutor îndeplinind mult mai eficient anumite sarcini în locul nostru.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "V.R.",
        description: "Virtual reality sunt medii 3D care simulează o realitate alternativă în care utilizatorii interacționează cu elemente virtuale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Filtru A.R.",
        description: "Aceste efecte specifice canalelor de social media modifică realitatea din imediata apropiere într-un mod de obicei tandru și amuzant.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Extended reality",
        description: "„Extended reality” este termenul ce descrie o realitate digitală similară cu lumea reală, dar ușor de modificat în funcție de ce nevoi avem.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Smartwatch",
        description: "„Ceas deștept” cu ecran digital și funcții utile dintre care cele mai multe sunt concentrate pe stilul de viață al utilizatorului.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "5G",
        description: "A cincea generație de rețea mobilă, destinată să asigure conexiunea tandră dintre toți și toate - oameni, lucruri și dispozitive smart.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Wireless",
        description: "Tehnologia care s-a desprins cu tandrețe de fire sau cabluri și transmite semnal cu ajutorul microundelor sau al undelor radio.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Internet of Things (IoT)",
        description: "Așa cum ciocolata Milka înseamnă combinația de lapte alpin cu cele mai potrivite ingrediente, IoT este combinația între tehnologia wireless și o rețea de miliarde de aparate smart gestionată de la distanță, cum ar fi un frigider care comandă singur ce lipsește.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Cyber security",
        description: "Toate programele și acțiunile care protejează calculatoarele și telefoanele pentru ca experiențele de navigare pe Internet să rămână unele tandre.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Mașini electrice",
        description: "Mai tandre cu mediul decât precursoarele lor pe combustibil fosil, mașinile electrice sunt mult mai sustenabile prin faptul că se alimentează cu energie electrică.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Mașină fără șofer",
        description: "Când nu visezi la o ciocolată Milka, nu-i așa că îți imaginezi o mașină care să meargă singură la destinație? Ei, bine, suntem foarte aproape! Deja există mașini care suplinesc rolul șoferului în multe privințe, precum accelerația sau chiar parcarea automată.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "Platformă de streaming",
        description: "Termen ce descrie o sursă online de conținut media - precum muzică sau filme - care poate fi savurat (alături de biscuiții preferați Milka Choc and Choc, nu-i așa?) fără nevoia de a-l descărca.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "technology",
        category: "Tehnologie",
        title: "3D printing",
        description: "Tehnologie prin care poți crea obiecte complicate cu ajutorul unei imprimante 3D care le „sculptează” cu tandrețe, în straturi, după un model prestabilit.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Table",
        description: "Jumătate strategie, jumătate noroc, un joc cu piese rotunde ce trebuie aduse „în casă” și apoi scoase înaintea adversarului. Pentru o partidă mai... competitivă se recomandă înlocuirea pieselor cu biscuiți Milka Choco minis.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Țintar",
        description: "În acest joc de strategie vechi de peste 3500 de ani, numit și „Moară”, scopul era deplasarea de-a lungul unor linii pentru a elimina piesele adversarului.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Oină",
        description: "Varianta românească, mai tandră, a jocului de baseball, în care două echipe încearcă să obțină cât mai multe puncte lovind mingea cu un baston, lovind jucătorii adverși cu mingea sau evitând să fie loviți până ajung în anumite puncte de pe teren.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Mersul la băi",
        description: "An de an, stațiunile balneare din România așteaptă să-și primească oaspeții cu tandrețe. De obicei, aceștia le vizitează pentru a-și trata diferite afecțiuni cu ajutorul apelor termale cu proprietăți curative.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Țurca",
        description: "Jocul copilăriei pentru mulți, țurca este relativ asemănător cu oina, doar că mai rudimentar, iar în loc de minge se folosește un bețișor. Își amintesc cu tandrețe de acest joc în special cei care au locuit sau și-au petrecut vacanțe la sate.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Baba Oarna",
        description: "Un joc simplu, practicat mai ales de copii încă din vremurile Greciei antice, presupune ca un jucător legat la ochi să-i prindă pe ceilalți jucători care aleargă în jurul lui și îl strigă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Șezătoare",
        description: "Adunare tandră a locuitorilor dintr-o zonă, ținută de obicei iarna la sat, în care se spun povești, glume, se discută aspecte importante sau se lucrează.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Bal",
        description: "Termen ce descria o petrecere unde se dansează. Uneori se poartă mască (bal mascat). În vremurile noastre cuvântul este cel mai des asociat cu petrecerile semioficiale ale liceelor, adică balul bobocilor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Bâlci",
        description: "Târg de distracții, zis și iarmaroc, în care se puteau cumpăra de la nasturi și vată pe băț la văcuțe și saci cu fructe, care se desfășura odinioară la periferia orașelor sau prin zonele rurale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Grădină de vară",
        description: "Oază tandră de natură în plin oraș, care se deschide doar pe timpul verii și unde petrecerile sau relaxarea sunt ferite de gălăgia urbană.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Taifas",
        description: "Pălăvrăgeală cu tentă intimă, sfaturi, discuție relaxată și tandră cu persoane familiare.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Cenaclu",
        description: "Întrunirea artistică a unui grup de scriitori și/ sau cântăreți cu aspirații și valori comune, precum și a publicului acestora.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Serată / Ceai dansant",
        description: "Petreceri tandre la care băutura oficială era ceaiul. Un grup de tineri se reuneau acasă la unul dintre ei ca să se cunoască și să se distreze dansând.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Flașnetar",
        description: "Muzicant ambulant, care învârtea mânerul unei flașnete, iar aceasta, la rândul ei, reda melodii. Uneori flașnetarul era însoțit de un papagal care, contra cost, extrăgea tandru un bilețel cu „preziceri” pentru doritori.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Jocul de-a v-ați ascunselea (Mijită)",
        description: "Jocul zilelor tandre ale copilăriei, pentru toate generațiile și de pe toate meridianele geografice. Un jucător își acoperă ochii (sau „stă la perete”) până se ascund toți ceilalți, apoi pleacă să-i găsească.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Țară-țară, vrem ostași",
        description: "Acest joc al copilăriei demonstra forța unei echipe. Membrii acesteia se țineau de mână, în timp ce un jucător desemnat de la echipa adversă trebuia să rupă în fugă acest lanț de mâini strânse, izbindu-se de el. Dacă nu reușea, trebuia să se alăture echipei care i-a rezistat.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Patine cu rotile",
        description: "Ei, bine, patine... dar cu rotile, 😊 numai bune pentru plimbări tandre pe aleile parcurilor. Încă sunt la modă, însă rolele nu mai sunt dispuse două câte două, ca pe vremuri, ci într-o singură linie, mai apropiată ca idee de lamele patinelor originale, de gheață.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Schi pe doage de butoi",
        description: "Rudele mai îndrăznețe ale săniilor din capace de plastic erau schiurile din doage (adică șipcile de lemn încovoiate care alcătuiesc un butoi), destul de populare pe derdelușurile de acum 30 de ani.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Ruble / capace",
        description: "Una dintre primele forme de însușire de proprietate a fost când trebuia să câștigi „rublele” altora (de obicei fețele unor cutii de chibrituri) scoțându-le cu un carton, de la distanță, dintr-un cerc trasat cu creta.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Pâr-poc",
        description: "Numit și „jocul de-a împușcatelea”, copiii suflau cu cornete prin țevi de plastic într-un soi de paintball mai rudimentar, de dinainte să devină cool.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Miuța",
        description: "Fotbalul la o singură poartă, practicat pe „stadionul” improvizat din spatele blocului, o zonă ce evocă o mulțime de jocuri tandre din copilărie.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Filatelie",
        description: "Pasiunea pentru colecționat cu tandrețe timbre de toate formele și culorile în clasoare de toate mărimile.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Board games",
        description: "Jocuri de societate desfășurate pe table de joc diverse, cu piese, cărți, figurine și zaruri, în care jucătorii urmează firul tandru al unei povești.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Voluntariat",
        description: "Activități desfășurate în scopul susținerii unei cauze. Oamenii care cred în ea îi dăruiesc, tandru, din timpul lor liber.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Cercetași",
        description: "Activități educative și sportive în care se învață noțiuni și practici de bază pentru supraviețuire. Termenul este versiunea românească pentru celebrii scouts americani.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Cățărări / hiking",
        description: "Activitatea de a escalada - pe munte sau în săli special amenajate - anumite obstacole și a ajunge cât mai sus folosind, pe cât posibil, doar propriul corp și câteva elemente pentru siguranță.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Skateboard",
        description: "Transpunerea în practică a ideii de a face surf pe uscat alunecând pe o placă prevăzută cu patru rotile. De aici până la acrobații aproape imposibile a fost doar un pas. Bine, un salt.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Munțomani",
        description: "Împătimiții tandri ai traseelor montane, montaniarzi. Sunt acele persoane care nu scapă niciun prilej să ajungă din nou la „a doua lor casă”, muntele.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Lapte gros",
        description: "Jucătorii unei echipe, aplecați în șir indian, cu capul între picioarele celui din față, trebuiau să ghicească un număr arătat „arbitrului” de jucătorii care le săreau în spate.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Leapșa",
        description: "Numit și „prinselea” sau „prinsa”, un jucător trebuia să alerge și să-l atingă pe unul dintre ceilalți, adică „să-i dea leapșa”. În momentul respectiv cel atins îi lua locul.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Meditație",
        description: "Prilej de autodescoperire și relaxare, meditația este un set de reguli de respirație, concentrare a atenției și mișcări sau gesturi fizice tandre care ajută oamenii să se simtă mai bine, mai calmi.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Hoverboard",
        description: "Practic, un skateboard electric care nu necesită alt efort pentru deplasare în afară de propriul echilibru, cu ajutorul căruia se și ghidează aparatul.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Provocări / Challenges",
        description: "„Provocările” de pe canalele de social media sunt ce este laptele alpin pentru Milka - ingredientul de bază pentru interacțiunile de pe Internet. Printre aceste provocări se pot număra imitarea unor gesturi, acțiuni în scopul unei acțiuni caritabile sau de distracție.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Catering",
        description: "Odată cu apariția sistemelor și aplicațiilor de livrare la domiciliu, deplasarea până la restaurant nu mai e mereu necesară. Doar câteva apăsări simple de buton și totul ajunge gata pregătit la ușă. Așa rămâne mai mult timp pentru tandrețe.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "e-shopping",
        description: "Prin intermediul aplicațiilor de e-shopping, orice lucru poate ajunge la ușă în cel mai scurt timp fără efort. Trebuie doar făcută comanda... Da, se poate comanda și Milka. 😉",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "lifestyle",
        category: "Lifestyle",
        title: "Swipe",
        description: "Termen specific folosirii aplicațiilor de social media pe telefonul mobil, care descrie gestul făcut cu un deget pe ecran pentru a accesa sau a șterge un conținut ori pentru a da acceptul pentru un anumit lucru. (Atenție, la unele aplicații contează direcția de swipe).",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Pardesiu",
        description: "Element care se găsea în orice garderobă. Un fel de palton subțire pentru zilele tandre de primăvară și toamnă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Încălțări de stradă",
        description: "Pentru a păstra atmosfera tandră din casă, existau într-o vreme două tipuri de încălțări, de casă și de stradă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Batic",
        description: "Țesătură, de obicei din pânză de in colorată, care se mai poartă și acum pe cap pentru a ține părul prins sau pentru a-l proteja de ploaie și vânt.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Tichie",
        description: "Un alt nume tandru pentru pălărie. Mai des întâlnit atunci când tichia este de mărgăritar, aceasta era o piesă de îmbrăcăminte de diferite forme, purtată pe creștetul capului.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Păr coafat permanent",
        description: "Modalitate de a oferi mai mult volum, dar și un aspect inedit părului, la mare modă într-o anumită perioadă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Mers la croitor",
        description: "Mai demult lucrurile erau mai ușor de întreținut decât de înlocuit, iar lumea se străduia să prelungească, tandru, viața obiectelor de care se atașa. Spre exemplu, hainele erau reparate sau ajustate la un croitor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Cingătoare",
        description: "Un fel de cordon mai lat folosit pentru a strânge cu tandrețe o haină mai lungă pe mijlocul corpului.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Izmene",
        description: "Acest articol de lenjerie era purtat, de obicei, de bărbați pe sub pantaloni pentru a le ține de cald.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Pieptar",
        description: "Vestă mai scurtă, de obicei ornamentală, care acoperă tandru restul hainelor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Bigudiu",
        description: "Obiect din plastic folosit de femei pentru obținerea buclelor spectaculoase. Pentru efect îndelungat se adăuga și o sursă puternică de căldură.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Set compleu",
        description: "Un costum compus din două sau trei piese de îmbrăcăminte potrivite perfect, precum laptele alpin cu ingredientele ciocolatei Milka.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Ilic",
        description: "Vestă ornamentală, fără mâneci, purtată cu mândrie tandră, de obicei în zonele rurale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Urson",
        description: "Blană de urs, dar nu chiar. Acest termen reprezintă o haină groasă, de iarnă, a cărei aspect imită o blană de urs.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Pâslari",
        description: "Ghete din pâslă pentru sezonul friguros, folosită pentru a proteja tandru altă pereche de încălțări.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Capot",
        description: "Haină pentru casă cu care femeile acoperă, de obicei, alte haine, la fel cum laptele alpin acoperă un strat de ingrediente delicioase la ciocolata Milka.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Jupon",
        description: "Fustă de corp, îmbrăcată cu tandrețe pe sub altă fustă ca să-i ofere acesteia mai mult volum.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Furou / Neglijeu",
        description: "Piesă de lenjerie feminină de zi, dantelată și confecționată de obicei dintr-o țesătură fină ca laptele alpin.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Târnică",
        description: "Coșuleț din material împletit în care se pot așeza cu tandrețe câteva obiecte de dimensiuni mici.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "boomer",
        theme: "personal",
        category: "Lifestyle",
        title: "Fâș",
        description: "Haină sau materialul subțire și impermeabil din care e confecționată, care apără, tandru, de vânt și de ploaie.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Gumari",
        description: "Articole de încălțăminte din cauciuc sau din materiale similare, care protejează cu tandrețe picioarele în condiții nefavorabile precum apa, noroiul, lucrul pe șantier etc.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Șal",
        description: "Țesătură de diverse dimensiuni și forme, din materiale fine, care se poartă cu tandrețe în jurul gâtului, pe umeri sau pe cap.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Păr tapat",
        description: "Păr căruia i s-a oferit mai mult volum printr-un procedeu special de pieptănare în direcția inversă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Strugurel",
        description: "Pe lângă un strugure mic, termenul mai definește și un tub asemănător unui ruj, cu o substanță solidă care hidratează buzele pentru a le oferi un aspect mai sănătos și mai tandru.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Espadrile",
        description: "Articol de încălțăminte confecționat din pânză și cu talpa dintr-un material ușor, perfect pentru plimbările tandre pe timp de vară.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Raniță",
        description: "Sac din materiale impermeabile pentru cărat în spate diverse obiecte, folosit mai degrabă de militari.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Ciocate",
        description: "Cizme, de obicei bărbătești, cu botul foarte ascuțit, care oferă un gen de eleganță tandră întregii ținute.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Drot",
        description: "Instrument electric, asemănător unui clește alungit, care se încălzește și ajută la ondularea părului, însă care nu este foarte tandru cu acesta.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Două piese (deux-pieces)",
        description: "Costum feminin alcătuit din două piese de îmbrăcăminte confecționate din același material.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Pantaloni bufanți",
        description: "Tip de pantaloni foarte largi, confortabili, ca niște șalvari, care erau strânși abia jos, pe gleznă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genX",
        theme: "personal",
        category: "Lifestyle",
        title: "Tunsoare bros (breton lăsat lung într-o parte)",
        description: "Tip de tunsoare, de obicei inegală, care pune mai tandru în evidență trăsăturile feței.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Adidași",
        description: "Nume provenit de la un anumit tip de pantofi sport, dar care a rămas împământenit ca numele generic pentru acest tip de încălțări ce a capătat un simbol de stil.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Balayage",
        description: "Tehnică folosită atunci când se dorește un păr care pare 'sărutat' de soare. Numele acesteia vine din francezul 'a mătura' datorită tehnicii tandre de vopsire care se realizează cu mâna liberă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Unghii cu gel",
        description: "Un stil de manichiură sau pedichiură cu ajutorul căruia o persoană își poate exprima tandru creativitatea, dar și care, datorită tehnicii de aplicare, are durată de viață mai mare.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Balerini",
        description: "Pantofi fără toc, inspirați de cei purtați de balerine, dar mult mai comozi, potriviți pentru aproape orice situație și care au fost alegerea cea mai des întâlnită într-o anumită perioadă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Jachetă de pluș",
        description: "Acest item de îmbrăcăminte descrie o jachetă, de obicei scurtă, care are la exterior un material moale și foarte plăcut la atingere, care îndeamnă la îmbrățișări tandre.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Skinny jeans",
        description: "Blugii au fost dintotdeauna la modă, în diverse forme, însă, pentru Mileniali, modelul skinny a reprezentat cel mai dorit tip de blugi, folosit în aproape toate situațiile sociale.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Bootcut jeans",
        description: "Blugii, acest item de îmbrăcăminte nemuritor, au luat forma unor pantaloni largi la baza, în zona 'ghetei' care a fost foarte în trend într-o anumită perioadă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Tweed",
        description: "Acest termen descrie un tip de stofă confecționată din fire de lână fină cu fibre lungi, de culori diferite, care a fost foarte mult timp materialul reprezentativ pentru o casă de modă renumită, simbol al eleganței.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Emo",
        description: "Un curent muzical cu note melancolice, triste, ce a adus cu el și un curent stilistic. Astfel, cei care ascultau muzica emo tindeau să se îmbrace cu culori închise, cu mesaje specifice, dar și să aibă coafuri și machiaje inspirate la fel de mult de acest tip de muzică.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Normcore",
        description: "Un trend din modă unisex care este caracterizat de purtarea de haine fără pretenții, logo-uri sau accesorii inutile, cu item-uri de îmbrăcăminte de bază tandre în simplitatea lor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Casual",
        description: "Așa cum ingredientele principale Milka sunt tandrețea, laptele alpin și ciocolata cremoasă, la fel și ingredientele principale ale acestui stil vestimentar sunt confortul, versatilitatea și simplitatea, care se situează între formal și haine lejere.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "millennial",
        theme: "personal",
        category: "Lifestyle",
        title: "Smart casual",
        description: "Acest cod vestimentar descrie un mix între stilul casual și cel office, mai serios, potrivit pentru birou. Confortul primează, însă unele dintre elementele unei ținute pot fi din zona business.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Sneakerși",
        description: "Nume dat pantofilor sport, care vine din limba engleză și care a devenit din în ce mai popular datorită creatorilor de conținut din alte țări.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Beanie",
        description: "Acest termen descrie tandru un tip de căciulă scurtă, inspirată din stilul pescarilor care se poartă doar pe vârful capului fără să acopere urechile.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Mullet",
        description: "Un stil de tunsoare, de cele mai multe ori masculină, care presupune păr mai scurt în față și în laterale, dar mai lung în spate.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Fit check",
        description: "Expresie folosită de cineva căruia îi atrage atenția o ținută, sau un outfit deosebit. Un stil vestimentar care atrage tandru toate privirile.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Drip",
        description: "Termen provenit din engleză care descrie un accesoriu sau o ținută foarte la modă, foarte cool, purtată de o persoană cu atitudinea potrivită, cu multă încredere în sine.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Fit",
        description: "Termen ce poate fi folosit ori pentru a descrie pe cineva cu o ținută nemaipomenită sau pentru a descrie o ținută în general, adică prescurtarea tandră de la 'outfit'.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Cosplay",
        description: "Vine de la englezul 'costume play' adică „joc costumat”, însă originea acestor ținute inspirate de personaje din filme, jocuri video și benzi desenate vine dintr-un tip de artă performance escapistă apărută în Japonia.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Mom jeans",
        description: "Termen ce descrie un tip de blugi cu talie înaltă purtat de tinerele mame în anii '80 și care erau extrem de la modă.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Y2K",
        description: "Deși a pornit ca numele dat anului 2000, acesta desemnează în modă nostalgia trendurilor din anii '90, începuturile anilor '00 cu siluetele tandre, dar maximaliste, pline de accesorii și culori tari.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "K-POP",
        description: "Un stil de muzică ajuns în centrul atenției și datorită Internetului, muzica pop coreeană e caracterizată de un mix tandru de stiluri muzicale - pop, muzică electronică, hip hop, rock, R&B.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Baggy",
        description: "Un stil de pantaloni cu turul lăsat care revin în trenduri și care devine popular atât în rândul băieților, cât și al fetelor.",
        shareImage: "",
        shareUrl: ""
      },
      {
        stepTwo: "genZ",
        theme: "personal",
        category: "Lifestyle",
        title: "Soft girls",
        description: "Un stil vestimentar adoptat de fete care înclină spre bluze supradimensionate asociate cu blugi sau fuste în căutarea confortului care lipsește hainelor mulate.",
        shareImage: "",
        shareUrl: ""
      },
    ]
  }
}