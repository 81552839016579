class LayoutHandler {
  constructor() {
    this.init();
    this.handleDOM();
    this.handleEvents();
  }

  /**
   * Declare global variables
   */
  init() {}

  /**
   * Handle DOM queries
   */
  handleDOM() {
    this.body = $(document.body);
    this.menuOpenTrigger = $(".menu--open");
    this.menuContainer = $(".menu-list");
    this.menuCloseTrigger = $(".menu--close");

    this.videoSlideToggle = $(".urmareste-jocul-swiper .swiper-slide");
    this.videoModal = $("#videoModal");
    this.closeVideoModal = $("#videoModal .close-modal");

  }

  /**
   * Listen for events
   */
  handleEvents() {
    this.menuOpenTrigger.on("click", () => {
      this.body.addClass("disable-scroll");
      this.menuContainer.addClass("menu-list--open");
    });

    this.menuCloseTrigger.on("click", () => {
      this.body.removeClass("disable-scroll");
      this.menuContainer.removeClass("menu-list--open");
    });

    this.videoSlideToggle.on("click", () => {
      const videoUrl = "https://www.youtube.com/embed/" + $(this).attr("data-videoid");

      this.videoModal.find("iframe").attr("src", videoUrl);
      this.videoModal.modal("show");
    });

    this.videoModal.on('hidden.bs.modal', () => {
      this.videoModal.find("iframe").attr("src", "");
    });

    setTimeout(() => {
      this.setBgImage();
    }, 150);

    if ($(".urmareste-jocul").length > 0) {
      new Swiper(".urmareste-jocul-swiper", {
        slidesPerView: 3,
        spaceBetween: 25,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          991: {
            slidesPerView: 2.2,
            spaceBetween: 20
          },
          575: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          }
        }
      });
    }


    if ($(".content__rewards").length > 0) {
      new Swiper(".reward-swiper", {
        slidesPerView: 3,
        spaceBetween: 22,
        allowSlideNext: false,
        allowSlidePrev: false,
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1199: {
            slidesPerView: 1,
            spaceBetween: 20,
            allowSlideNext: true,
            allowSlidePrev: true,
            allowTouchMove: true,
          }
        }
      });
    }


  }

  setBgImage() {
    jQuery("*[data-image]").each(function () {
      let imageUrl = jQuery(this).attr("data-image");

      if (imageUrl) {
        jQuery(this).css("background-image", "url(" + imageUrl + ")");
      }
    });
  }
}
