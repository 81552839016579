class FormHandler {
    constructor() {
        if (jQuery(".register").length === 0) {
            return;
        }

        this.init();
        this.handleDOM();
        this.handleEvents();
    }

    /**
     * Declare global variables
     */
    init() {
    }

    /**
     * Handle DOM queries
     */
    handleDOM() {
        this.form = $("#registerForm");
        this.formSubmit = $('#form-submit');
        this.formAction = $(".form-action");
        this.phoneInput = $('#phone');
        this.storeInput = $("#store");
        this.receiptInput = $('#receiptNumber');
        this.dateInput = $('#date');
        this.rulesInput = $('#rules');
        this.termsInput = $('#terms');
        this.ageInput = $('#age');
        this.modal = $("#errorModal");
        this.errorDiv = $(".error-messages");
        this.receiptModal = $('#receiptModal');
        this.formWrapper = $(".form-wrapper");
        this.store = $("#store");
        this.info = $(".open-receipt-modal");
        this.infoWrapperMobile = $(".info-wrapper-mobile");
        this.closeReceipt = $(".closeModal");
        this.closeReceiptBootstrap = $(".closeModalBootstrap");
        this.receiptModalBootstrap = $('#receiptModalBootstrap');
        this.modalResponse = $("#modalForm");
        this.closeModalForm = $(".closeModalBtn");
        this.closeModal = $(".close-modal");
        this.messageModal = $("#messageModal");
        this.iconSuccess = $(".success");
        this.iconVoucher = $(".voucher");
        this.iconCamera = $(".camera");
        this.iconDouble = $(".double");
        this.iconBlockedCorrect = $(".blocked-correct");
        this.iconBlockedCorrectLastWeek = $(".blocked-correct-last-week");
        this.iconPostCampanie = $(".post-campanie");
        this.successModal = $('.succes-message');
        this.successResetForm = $('.succes-message .go-to-register');
        this.closeModalBtn = document.querySelector('.modal-close');
        this.modalMessage = $('.modalMessage');
    }

    /**
     * Listen for events
     */
    handleEvents() {
        let self = this;
        var local = true;

        let imagesBon = [
            {
                name: 'carrefour',
                image: `${absolutePath}/bonuri/Carrefour.png`,
            }, {
                name: 'cora',
                image: `${absolutePath}/bonuri/Cora.png`,
            },
            {
                name: 'kaufland',
                image: `${absolutePath}/bonuri/Kaufland.png`,
            },
            {
                name: 'mega',
                image: `${absolutePath}/bonuri/Mega-Image.png`,
            },
            {
                name: 'metro',
                image: `${absolutePath}/bonuri/Metro.png`,
            },
            {
                name: 'penny',
                image: `${absolutePath}/bonuri/Penny.png`,
            },
            {
                name: 'profi',
                image: `${absolutePath}/bonuri/Profi.png`,
            },
            {
                name: 'selgros',
                image: `${absolutePath}/bonuri/Selgros.png`,
            },
            {
                name: 'altele',
                image: `${absolutePath}/bonuri/Kaufland.png`,
            },
            {
                name: '',
                image: `${absolutePath}/bonuri/Kaufland.png`,
            }
        ];

        this.info.on("click", function (e) {
            e.preventDefault();
            self.receiptModal.toggleClass("active");
        });

        this.messageModal.on('hide.bs.modal', function () {
            setTimeout(function (e) {
                self.iconSuccess.removeClass('active');
                self.iconVoucher.removeClass('active');
                self.iconCamera.removeClass('active');
                self.iconDouble.removeClass('active');
                self.iconBlockedCorrect.removeClass('active');
                self.iconBlockedCorrectLastWeek.removeClass('active');
                self.iconPostCampanie.removeClass('active');
            }, 500);
        });

        this.closeModal.on("click", function (e) {
            e.preventDefault();
            setTimeout(function (e) {
                self.iconSuccess.removeClass('active');
                self.iconVoucher.removeClass('active');
                self.iconCamera.removeClass('active');
                self.iconDouble.removeClass('active');
                self.iconBlockedCorrect.removeClass('active');
                self.iconBlockedCorrectLastWeek.removeClass('active');
                self.iconPostCampanie.removeClass('active');
            }, 500);

            self.messageModal.modal("hide");
        });

        this.closeModalForm.on("click", function (e) {
            e.preventDefault();
            self.modalResponse.modal("hide");
        });

        this.closeReceipt.on("click", function (e) {
            e.preventDefault();
            self.receiptModal.toggleClass("active");
        });

        this.closeReceiptBootstrap.on("click", function (e) {
            e.preventDefault();
            self.receiptModalBootstrap.modal('hide');
        });

        this.closeModalBtn.addEventListener('click', function(e){
            e.preventDefault();
            self.modalMessage.modal('hide');
        })

        // this.infoWrapperMobile.on("click", function (e) {
        //   e.preventDefault();
        //   self.receiptModalBootstrap.modal('show');
        // });

        // this.modalResponse.modal("show");

        /**
         * Add custom rules and validate the form
         */
        $.validator.addMethod("digitsonly", function (value, element) {
            return this.optional(element) || /^[0-9]+$/g.test(value);
        }, "");
        $.validator.addMethod("phoneRO", function (phone_number, element) {
            phone_number = phone_number.replace(/\(|\)|\s+|-/g, "");
            return this.optional(element) || phone_number.length > 9 &&
                phone_number.match(/^(?:(?:07\d{2}\S?\d{3}\S?\d{3}|(21|31)\d{1}\S?\d{3}\S?\d{3}|((2|3)[3-7]\d{1})\S?\d{3}\S?\d{3}|(8|9)0\d{1}\S?\d{3}\S?\d{3}))$/);
        }, "");
        $.validator.addMethod("recipeRegex", function (value, element) {
            return this.optional(element) || /^(?![\s-0]*$)\d{1,25}(?:[\s-]\d{1,25})*$/g.test(value);
        }, "");
        $.validator.addMethod("valueNotEquals", function (value, element, arg) {
            return arg !== value;
        }, "");

        //FOR PRODUCTION
        // local time: end campaign date
        // startDate 2 OCT
        // endDate 12 NOV

        //Start/current date is 2 OCT
        // let endCampaignDate = 1699826399; //12 NOV - For live server
        //
        // //LIVE timestamps
        // let campaignMaxDate = null;
        // let currentDate = Math.floor(Date.now() / 1000);
        //
        // if (currentDate <= endCampaignDate) {
        //     campaignMaxDate = new Date();
        // } else {
        //     campaignMaxDate = new Date(2023, 11 - 1, 12);
        // }



        //FOR TESTING
        // local time: end campaign date
        // startDate 1 AUG
        // endDate 30 SEP
        let endCampaignDate = 1696107599; //30 SEP

        let campaignMaxDate = null;
        let currentDate = 1690837201; // 1 AUG

        if (currentDate <= endCampaignDate) {
            campaignMaxDate = new Date();
        } else {
            campaignMaxDate = new Date(2023, 9 - 1, 30);
        }

        this.dateInput.datepicker({
            monthNames: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'], // set month names
            monthNamesShort: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec'], // set short month names
            dayNames: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbată'], // set days names
            dayNamesShort: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâb'], // set short day names
            dayNamesMin: ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ'], // set more short days names
            dateFormat: 'dd/mm/yy',
            minDate: new Date(2023, 8 - 1, 1), //for TESTING
            // minDate: new Date(2023, 10 - 1, 2), // for LIVE
            maxDate: campaignMaxDate,
            orientation: 'bottom'
        });

        this.dateInput.on('change', function (e) {
            $(this).valid();
        });

        this.form.validate({
            rules: {
                phone: {
                    digitsonly: true,
                    required: true,
                    minlength: 10,
                    maxlength: 10,
                    number: true,
                    phoneRO: true
                },
                store: {
                    required: true
                },
                receiptNumber: {
                    required: true,
                    maxlength: 25,
                    recipeRegex: true,
                    number: false,
                    digitsonly: false,
                    phoneRO: false
                },
                date: {
                    required: true,
                    number: false,
                    digitsonly: false,
                    phoneRO: false
                },
                rules: {
                    required: true,
                    number: false,
                    digitsonly: false,
                    phoneRO: false
                },
                terms: {
                    required: true,
                    number: false,
                    digitsonly: false,
                    phoneRO: false
                },
                age: {
                    required: true,
                    number: false,
                    digitsonly: false,
                    phoneRO: false
                }
            },
            messages: {
                phone: {
                    required: 'Numărul de telefon este incomplet',
                    minlength: 'Numărul de telefon este incomplet',
                    maxlength: 'Trebuie să introduci un număr de telefon valid',
                    number: 'Trebuie să introduci un număr de telefon valid',
                    digitsonly: 'Trebuie să introduci un număr de telefon valid',
                    phoneRO: 'Trebuie să introduci un număr de telefon valid',
                },
                store: {
                    required: "Trebuie să selectezi un magazin",
                },
                receiptNumber: {
                    required: 'Trebuie să introduci un numar de bon valid',
                    maxlength: 'Trebuie să introduci un numar de bon valid',
                    recipeRegex: 'Trebuie să introduci un numar de bon valid',
                },
                date: {
                    required: 'Trebuie să introduci data bonului fiscal',
                },
                rules: {
                    required: 'Trebuie să bifezi că ai citit și că ești de acord cu <b>Regulamentul campaniei</b>',
                },
                terms: {
                    required: 'Trebuie să bifezi că ai citit și că ești de acord cu <b>Termenii și condițiile</b>',
                },
                age: {
                    required: 'Trebuie să bifezi că declari că ai peste 18 ani',
                }
            },
            errorElement: 'div',

            highlight: function (element) {
                // $(element).addClass("error");
                $(element).parents(".form-group").addClass("errorhighlight");
                $(element).parents(".checkbox-group").addClass("errorhighlight");
            },
            unhighlight: function (element) {
                // $(element).removeClass("error");
                $(element).parents(".form-group").removeClass("errorhighlight");
                $(element).parents(".checkbox-group").removeClass("errorhighlight");
            },

            // errorPlacement: function (error, element, e) {
            //     error.appendTo(self.errorDiv);
            // },
            
            submitHandler: function (form, event) {
                event.preventDefault();
                //
                //
                // fbq('track', 'Trimite');
                // gtag_report_conversion();
                //
                // if ("ga" in window) {
                //
                //     let tracker = ga.getAll()[0];
                //     if (tracker) {
                //         tracker.send("event", 'Sectiune Inscrie-te', "click", 'Click - Trimite Bon');
                //     }
                // }

                jQuery.ajax({
                    method: "POST",
                    url: "/register-form",
                    data: $("#registerForm").serialize(),
                    cache: false,
                    beforeSend: function () {
                        self.formWrapper.addClass("show-loader");
                        // self.formWrapper.closest($('.register-form')).addClass('hidden');
                        self.successModal.removeClass("hidden");
                    },
                    success: function (response) {
                        if (response.status) {
                            self.resetForm();
                            self.resetRecaptcha();
                        }

                        switch (response.messageStatus) {
                            case 'precampanie':
                            case 'postcampanie':
                            case 'recaptcha':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            case 'success':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            case 'prize-1':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            case 'prize-2':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            case 'prize-3':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            case 'blocked_correct':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            case 'incorect':
                            case 'dubla':
                                self.displayModal(self.messageModal, response.message);
                                break;
                            default:
                                self.displayModal(self.messageModal, response.message);
                        }
                    },
                    complete: function () {
                        self.formWrapper.removeClass("show-loader");
                    },
                    error: function () {
                        self.errorDiv.html('<p>A intervenit o eroare neprevăzută. Te rugăm să încerci din nou mai târziu.</p>');
                    }
                });
            }


        });

        this.successResetForm.on("click", function (e) {
            e.preventDefault();
            self.successModal.addClass("hidden");
            // self.formWrapper.closest($('.register-form')).removeClass('hidden');

            self.resetRecaptcha();
            self.resetForm();
        });


        this.storeInput.on('change', function () {
            // On select change, switch image for receipt
            let bonImg = $('#receiptModal img');
            // let bonImgModal = $('#receiptModalBootstrap img');
            let option = $(this).val();

            for (let i = 0; i < imagesBon.length; i++) {
                if (option === imagesBon[i].name) {
                    bonImg.attr('src', imagesBon[i].image);
                    // bonImgModal.attr('src', imagesBon[i].image);
                }
            }
        });

    }

    /**
     * Function of reseting form
     */
    resetForm() {
        this.phoneInput.val('');
        this.store.val('');
        this.receiptInput.val('');
        this.dateInput.val('');
        this.rulesInput.prop('checked', false);
        this.rulesInput.attr('checked', false);
        this.termsInput.prop('checked', false);
        this.termsInput.attr('checked', false);
        this.ageInput.prop('checked', false);
        this.ageInput.attr('checked', false);
    };


    displayModal(modalObj, message) {
        modalObj.find(".response").html(message);
        modalObj.modal('show');
    };

    /**
     * Function of resetting recaptcha
     */
    resetRecaptcha() {
        setTimeout(function () {
            // reset captcha
            grecaptcha.reset();
        }, 500)
    }
}
